import React, {Component} from "react";
import StackGrid from "react-stack-grid";
import {Loading} from "./widgets";

class Search extends Component {
    constructor(props) {
        super(props);
        this.state = {
            hits: [],
            loading: true,
            search: ""
        }
    }

    componentDidMount() {
        this.loadData();
        this.setState({loading: false});
    }

    handleChange(event) {
        this.setState({search: event.target.value.replace(/[^A-Za-z\sæøåÆØÅäöüÄÖÜ]/g, '')}, this.loadData);

        this.loadData();
    }

    loadData() {
        console.log("this.state.search : " + this.state.search)
        let API = 'https://trend-api.trendmodels.no/backend/search.php?search=';
        let DEFAULT_QUERY = this.state.search;
        if (this.state.search.length > 2) {
            console.log(API + DEFAULT_QUERY);
            fetch(API + DEFAULT_QUERY)
                .then(response => response.json())
                .then(data => this.setState({hits: data.records}));
        } else {
            this.setState({hits: null})
        }
    }

    addToSavedFaces(id) {
        let temp3 = JSON.parse(localStorage.getItem('savedFaces'));
        temp3[Object.keys(temp3).length] = id;
        localStorage.setItem('savedFaces', JSON.stringify(temp3));
    }

    render() {
        let lists;

        const {hits, loading} = this.state;

        if (hits != null) {
            console.log("hits: ", hits);
            lists = (
                <StackGrid
                    gridRef={grid => this.grid = grid}
                    itemComponent="div"
                    monitorImagesLoaded={true}
                    columnWidth={300}
                    gutterWidth={20}
                >
                    {hits.map((casen) => (

                        <div key={casen.ID} className="testimage1">
                            <img
                                src={"https://www.trendmodels.no/modeller/" + casen.ID + "/thumbs/" + casen.filnavn}
                                alt={casen.fornavn}
                                className="modelimg"
                                onClick={(event) => {
                                    this.props.history.push("/models/" + casen.ID)
                                }}
                            />
                            {casen.fornavn} {casen.etternavn.charAt(0)}
                        </div>

                    ))}
                </StackGrid>
            )
        }
        ;

        // let thisPath = this.props.location.pathname;
        // thisPath = thisPath.substring(0, thisPath.lastIndexOf("/") + 1)

        if (!loading) {
            return (
                <div>
                    <div>
                        <div className="pageheading">
                            {this.props.heading}
                        </div>
                        <div className="search-container">
                            <form className="" onSubmit={e => {
                                e.preventDefault();
                            }}>
                                <label for="searchfield">Enter at least three letters:</label><br/>
                                <input name="searchfield" className="searchfield"
                                       onChange={this.handleChange.bind(this)} type="text" placeholder="Search"
                                       aria-label="Search"/>
                            </form>
                        </div>

                    </div>


                    <div id="modellkort">
                        {lists}
                    </div>

                    <div className="col-md-2">&nbsp;</div>
                </div>
            );
        } else {
            return <Loading/>;
        }
    }
}

export default Search;