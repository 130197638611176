import React, {Component} from "react";
import {Loading} from "./widgets";
import { PDFDownloadLink, Document, Page, View, Text, Font, Image, Link} from '@react-pdf/renderer'



class Savedfaces extends Component {
    constructor(props) {
        super(props);
        this.state = {
            hits: [],
            loading: true,
            url: ""
        }
    }

    componentDidMount() {
        this.setState({hits: JSON.parse(localStorage.getItem('savedFaces'))})
        this.loadData();
        this.setState({loading: false});
    }


    loadData() {

        let API = 'https://trend-api.trendmodels.no/backend/savedfaces.php';
        let DEFAULT_QUERY = "?";
        let temp = JSON.parse(localStorage.getItem('savedFaces'))
        if (temp.length > 0) {

            for (var key in temp) {
                DEFAULT_QUERY += ("m[]="+temp[key]+"&");
            }

            let codedquery =  window.btoa(DEFAULT_QUERY)
            console.log("coded: " + codedquery)

            this.setState({url:codedquery})

            console.log(API + DEFAULT_QUERY);

            fetch(API + DEFAULT_QUERY)
                .then(response => response.json())
                .then(data => this.setState({hits: data.records}));
        }
    }

    addToSavedFaces(id) {
        let temp3 = JSON.parse(localStorage.getItem('savedFaces'));
        temp3[Object.keys(temp3).length] = id;
        localStorage.setItem('savedFaces', JSON.stringify(temp3));
        // console.log("localstorage: " + localStorage.getItem('savedFaces'))
    }

    removeFromSavedFaces(id) {
        let newSavedFaces = [];
        // console.log("Removing " + id);
        let temp = JSON.parse(localStorage.getItem('savedFaces'))

        for (var key in temp) {
            if (temp[key] !== id) {

                let newKey = Object.keys(newSavedFaces).length;
                //   console.log("Newkey: " + newKey)
                newSavedFaces[newKey] = temp[key];
            }
        }
        localStorage.setItem('savedFaces', JSON.stringify(newSavedFaces));

    }



    render() {
        let lists;

        const { hits, loading, url } = this.state;

        const styles = {
            text: {
                fontSize:22,
                fontFamily: 'Oswald',
                color: '#212121',
            },
            text2: {
                fontSize:12,
                fontFamily: 'Oswald',
                color: '#212121',
            },
            link: {
                fontSize:14,
                fontFamily: 'Oswald',
                color: '#000044',
            },
            image: {
                width: '200',
                padding: 0,
                align: 'left',
                overflow: 'hidden',
                left:0,
                top:0,
                right:0,
                bottom:0
            },
            page: {
                padding: 20,

            },
            box: { width: '100%', marginBottom: 30, borderRadius: 5 },
            box2: {
                position: 'absolute',
                left: 0,
            },
            box3: {
                position: 'absolute',
                left: 210,
            },
            pageNumbers: {
                position: 'absolute',
                bottom:10,
                textAlign: 'center',
                fontSize: 10,
                color: 'grey',
            },
        };
        Font.register({
            family: 'Oswald',
            src: 'https://fonts.gstatic.com/s/oswald/v13/Y_TKV6o8WovbUd3m_X9aAA.ttf',
        });

        console.log("hits: ", hits);

        let year = new Date().getFullYear();
        let month = new Date().getMonth()+1;
        let day = new Date().getDate();

        const doc = (
            <Document>

                <Page style={styles.page} size="A4" wrap="true" orientation="portrait">
                    {hits.map(pdfdata => (

                        <View key={pdfdata.ID} style={[styles.box, { height: 380, backgroundColor: 'white' }]}>
                            <Text style={styles.text2}>
                                First page is blank for some reason.
                            </Text>
                            <View style={styles.box2}>
                                <Link style={styles.link} src={ "https://www.trendmodels.no/models/" + pdfdata.ID }><Image style={styles.image} src={ "/modeller/" + pdfdata.ID + "/thumbs/" + pdfdata.filnavn } /></Link>
                            </View>
                            <View style={styles.box3}>
                                <Text style={styles.text}>
                                    {pdfdata.fornavn} {pdfdata.etternavn}
                                </Text>
                                <Text style={styles.text2}>
                                    Height: {pdfdata.hoyde} cm
                                </Text>
                                <Text style={styles.text2}>
                                    Dress size: {pdfdata.klaer}
                                </Text>
                                <Text style={styles.text2}>
                                    Shoes: {pdfdata.sko}
                                </Text>
                                <Text style={styles.text2}>
                                    Hair: {pdfdata.haarfarge}
                                </Text>
                                <Text style={styles.text2}>
                                    Eyes: {pdfdata.oyenfarge}
                                </Text>
                                <Text>
                                    <Link style={styles.link} src={ "https://www.trendmodels.no/models/" + pdfdata.ID }>Click here to view more details</Link>
                                </Text>
                            </View>
                        </View>
                    ))}

                    <Text style={styles.pageNumbers} fixed>
                        Trend Models AS - 7018 Trondheim, Norway (c) {year}
                    </Text>
                </Page>
            </Document>
        );



        lists = (

            <div className="si-containercontainer">
                {hits.map(casen => (

                        <div className="si-grid-container" key={casen.ID} >
                            <div className="si-left"></div>
                            <div className="si-image">

                                <img
                                    src={ "/modeller/" + casen.ID + "/thumbs/" + casen.filnavn }
                                    alt={casen.fornavn}
                                    className="si-modelimg"
                                    onClick={(event) => {
                                        this.props.history.push("/models/" + casen.ID)
                                    }}
                                />


                            </div>
                            <div className="si-navn">Navn: {casen.fornavn} {casen.etternavn}</div>
                            <div className="si-height">Height: {casen.hoyde} cm</div>
                            <div className="si-dress">Dress size: {casen.klaer}</div>
                            <div className="si-shoes">Shoes: {casen.sko} </div>
                            <div className="si-hair">Hair: {casen.haarfarge} </div>
                            <div className="si-eyes">Eyes: {casen.oyenfarge} </div>


                            <div className="si-right"></div>

                        </div>


                    )

                )}
            </div>
        );

        console.log("docs: ", doc);

        if (!loading) {
            return (
                <div>
                    <div>

                        <div className="centered pageheading">
                            {this.props.heading}
                        </div>
                        <div className="a5">
                            {hits.length >0 ? <div>
                                    <button type="button" onClick = {() => this.props.history.push("/savedfaces/" + url)} className="btn fakedropdown">GO TO URL</button>
                                    <PDFDownloadLink document={doc} fileName={"Trendmodels_Suggestions_" + day + "-" + month + "-" + year + ".pdf"}>
                                        {({ blob, url, loading, error }) => (loading ? 'Loading document...' : <button type="button" className="btn fakedropdown">SAVE AS PDF</button>)}
                                    </PDFDownloadLink>
                                </div>


                                :
                                <div><h5>Saved Faces is a tool for you to send your suggestions <br/>to clients or collegues. <br/>You can add a model to the Saved Faces section by viewing a specific model <br/>and pressing the "add to saved faces" button.</h5></div>
                            }
                            {this.menu}
                        </div>

                    </div>


                    <div id="modellkort">
                        {lists}
                    </div>

                    <div className="col-md-2">&nbsp;</div>
                </div>
            );
        } else {
            return <Loading />;
        }
    }
}
export default Savedfaces;