//@flow
import React, {Component} from "react";
import StackGrid from "react-stack-grid";
import {
    Loading, women_u, women_p, men_p, men_u
} from "./widgets";
import {Link} from "react-router-dom";

class Models2 extends Component<{
    match: { params: { id: number } }
}> {
    constructor(props) {
        super(props);
        this.state = {
            hits: [],
            loading: true,
            width: 0,
            height: 0
        };

        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    }


    componentDidMount() {

        if ((this.props.filter !== "men") && (this.props.filter !== "women")) {

            const API = 'https://trend-api.trendmodels.no/backend/allmodels.php?filter=';
            const DEFAULT_QUERY = this.props.filter;
            console.log("URL: " + API + DEFAULT_QUERY);
            fetch(API + DEFAULT_QUERY)
                .then(response => response.json())
                .then(data => this.setState({hits: data.records}));
        } else {
            this.loading = false;
        }
        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);
    }


    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
    }

    updateWindowDimensions() {
        this.setState({ width: window.innerWidth, height: window.innerHeight });
        // console.log("this.state.width: " + this.state.width)
    }



    eleContainsInArray(arr, element) {

        if (arr != null && arr.length > 0) {
            for (let i = 0; i < arr.length; i++) {
                if (arr[i] === element)
                    console.log("Checking if ", arr[i], " equals " + element);
                return true;
            }
        }
        return false;
    }


    existsInSavedFaces(id) {
        let temp = JSON.parse(localStorage.getItem('savedFaces'));
        for (let key in temp) {
            if (temp[key] === id) {
                return true
            }
        }
        return false
    }

    removeFromSavedFaces(id) {
        let newSavedFaces = [];
        // console.log("Removing " + id);
        let temp = JSON.parse(localStorage.getItem('savedFaces'));

        for (let key in temp) {
            if (temp[key] !== id) {
                //     console.log(temp[key]);
                let newKey = Object.keys(newSavedFaces).length;
                //   console.log("Newkey: " + newKey)
                newSavedFaces[newKey] = temp[key];
            }
        }
        localStorage.setItem('savedFaces', JSON.stringify(newSavedFaces));

        let handleToUpdate = this.props.handleToUpdate;
        handleToUpdate(JSON.stringify(newSavedFaces))

    }


    addToSavedFaces(id) {
        console.log("localStorage.getItem('savedFaces') ==> " + localStorage.getItem('savedFaces'));
        let temp3 = JSON.parse(localStorage.getItem('savedFaces'));

        console.log(typeof temp3);
        console.log("temp3: ", temp3);

        let test = this.eleContainsInArray(localStorage.getItem('savedFaces'), id);
        console.log("test: " + test);

        let newKey = Object.keys(temp3).length;
        console.log("Newkey: " + newKey);
        temp3[newKey] = id;
        localStorage.setItem('savedFaces', JSON.stringify(temp3));
        console.log("localstorage: " + localStorage.getItem('savedFaces'));

        let handleToUpdate = this.props.handleToUpdate;
        handleToUpdate(JSON.stringify(temp3))
    }

    render() {
        let lists;
        const {hits} = this.state;

        let thisPath = this.props.location.pathname;
        thisPath.indexOf(1);
        thisPath = thisPath.split("/")[1];
        console.log("Path: ", thisPath);

        function PickMenu(kategori) {
            switch (kategori) {
                case 'men':
                    return men_p;
                case 'women':
                    return women_p;
                case 'newfaces':
                    return women_u;
                case 'w_fashion':
                    return women_u;
                case 'w_model':
                    return women_u;
                case 'c_girl':
                    return women_u;
                default:
                    return men_u;
            }
        }

        if ((hits.length === 0) && (this.props.filter !== "men") && (this.props.filter !== "women")) {
            lists = (
                <Loading/>
            );
        } else {

            let columnwidth;
            this.state.width > 600 ? (columnwidth = 300) : (columnwidth = 150);
            lists = (
                <StackGrid
                    gridRef={grid => this.grid = grid}
                    itemComponent="div"
                    monitorImagesLoaded={false}

                    columnWidth={columnwidth}
                    gutterWidth={7}
                >
                    {hits.map(casen => (
                        <div key={casen.ID}>
                            <div className="profilbilde_kontainer">
                                <Link to={"/models/" + casen.ID}><img src={"https://www.trendmodels.no/modeller/" + casen.ID + "/thumbs/" + casen.filnavn}
                                    alt={casen.fornavn}
                                    className="profilbilde"
                                /></Link></div>
                            <div className="profilbilde_tekst">
                                {casen.fornavn} {casen.etternavn.charAt(0)}
                            </div>
                            <div className="modelloversikt_addtosavedfaces">
                                {
                                    (
                                        this.existsInSavedFaces(casen.ID) ?
                                            <div className="plussminus klikkbar noselect" onClick={() => {
                                                this.removeFromSavedFaces(casen.ID)
                                            }}>-</div>
                                            :
                                            <div className="plussminus klikkbar noselect" onClick={() => {
                                                this.addToSavedFaces(casen.ID)
                                            }}>+</div>
                                    )}
                            </div>
                        </div>
                    ))}
                </StackGrid>
            );


        }


        if (!this.loading) {
            return (
                <div>

                    <div>
                        {PickMenu(this.props.filter)}
                    </div>

                    <div className="parent">
                        {lists}
                    </div>
                    <div className="col-md-2">&nbsp;</div>
                </div>
            );
        } else {
            return <Loading/>;
        }
    }

}

//


export default Models2;
