import * as React from 'react';
import { Component } from 'react';
import {Link} from 'react-router-dom';


console.log = function() {};

export const men_u = (

    <div className="submenu-container">
        <div className="a1">
            <Link to="/male/development/" className="small-link fakedropdown">DEVELOPMENT
            </Link>
        </div>
        <div className="a2">
            <Link to="/male/mainboard/" className="small-link fakedropdown">MAINBOARD
            </Link>
        </div>
        <div className="a3">
            <Link to="/male/commercial/" className="small-link fakedropdown">COMMERCIAL
            </Link>
        </div>
        <div className="a4">
            <Link to="/male/youth/" className="small-link fakedropdown">YOUTH
            </Link>
        </div>


    </div>
);

export const women_u = (

    <div className="submenu-container">
        <div className="a1">
            <Link to="/female/development/" className="small-link fakedropdown">DEVELOPMENT
            </Link>
        </div>
        <div className="a2">
            <Link to="/female/mainboard/" className="small-link fakedropdown">MAINBOARD
            </Link>
        </div>
        <div className="a3">
            <Link to="/female/commercial/" className="small-link fakedropdown">COMMERCIAL
            </Link>
        </div>
        <div className="a4">
            <Link to="/female/youth/" className="small-link fakedropdown">YOUTH
            </Link>
        </div>


    </div>
);

export const women_p = (
    <div className="submenu-image-container">
        <div className="a1">
            <Link to="/female/development/">
                <img src="/images/mod1.jpg" className="submenu_img" alt="View models in development at www.trendmodels.no"/>
            </Link>
        </div>
        <div className="a2">
            <Link to="/female/mainboard/">
                <img src="/images/mod2.jpg" className="submenu_img" alt="View models in fashion at www.trendmodels.no"/>
            </Link>
        </div>
        <div className="a3">
            <Link to="/female/commercial/">
                <img src="/images/mod3.jpg" className="submenu_img" alt="View classic models at www.trendmodels.no"/>
            </Link>
        </div>
        <div className="a4">
            <Link to="/female/youth/">
                <img src="/images/mod4.jpg" className="submenu_img" alt="View younger models at www.trendmodels.no"/>
            </Link>
        </div>
    </div>
);


export const men_p = (
    <div className="submenu-image-container">
        <div className="a1">
            <Link to="/male/development/">
                <img src="/images/mod5.jpg" className="submenu_img" alt="View models in development at www.trendmodels.no"/>
            </Link>
        </div>
        <div className="a2">
            <Link to="/male/mainboard/">
                <img src="/images/mod6.jpg" className="submenu_img" alt="View models in fashion at www.trendmodels.no"/>
            </Link>
        </div>
        <div className="a3">
            <Link to="/male/commercial/">
                <img src="/images/mod7.jpg" className="submenu_img" alt="View classic models at www.trendmodels.no"/>
            </Link>
        </div>
        <div className="a4">
            <Link to="/male/youth/">
                <img src="/images/mod8.jpg" className="submenu_img" alt="View younger models at www.trendmodels.no"/>
            </Link>
        </div>
    </div>
);



export class Alert extends Component {
    alerts: { text: React.Node, type: string }[] = [];

    render() {
        return (
            <>
                {this.alerts.map((alert, i) => (
                    <div key={i} className={'alert alert-' + alert.type} role="alert">
                        {alert.text}
                        <button
                            className="close"
                            onClick={() => {
                                this.alerts.splice(i, 1);
                            }}
                        >
                            &times;
                        </button>
                    </div>
                ))}
            </>
        );
    }

    static success(text: React.Node) {
        // To avoid 'Cannot update during an existing state transition' errors, run after current event through setTimeout
        setTimeout(() => {
            for (let instance of Alert.instances()) instance.alerts.push({ text: text, type: 'success' });
        });
    }

    static info(text: React.Node) {
        // To avoid 'Cannot update during an existing state transition' errors, run after current event through setTimeout
        setTimeout(() => {
            for (let instance of Alert.instances()) instance.alerts.push({ text: text, type: 'info' });
        });
    }

    static warning(text: React.Node) {
        // To avoid 'Cannot update during an existing state transition' errors, run after current event through setTimeout
        setTimeout(() => {
            for (let instance of Alert.instances()) instance.alerts.push({ text: text, type: 'warning' });
        });
    }

    static danger(text: React.Node) {
        // To avoid 'Cannot update during an existing state transition' errors, run after current event through setTimeout
        setTimeout(() => {
            for (let instance of Alert.instances()) instance.alerts.push({ text: text, type: 'danger' });
        });
    }
}

//might be implemented later
/*export class TitleBanner extends Componenet <{ id: string }> {

    render(){
        <div className="title-banner">
            <h1> </h1>
        </div>
    }

}*/


class FormInput extends Component<{
    type: string,
    label: React.Node,
    value: mixed,
    onChange: (event: SyntheticInputEvent<HTMLInputElement>) => mixed,
    required?: boolean,
    pattern?: string
}> {
    render() {
        return (
            <div className="form-group row ">
                <label className="col-sm-6 col-form-label">{this.props.label}</label>
                <div className="col-sm-4">
                    <input
                        className="form-control"
                        type={this.props.type}
                        value={this.props.value}
                        onChange={this.props.onChange}
                        required={this.props.required}
                        pattern={this.props.pattern}
                    />
                </div>
            </div>
        );
    }
}


export class Loading extends Component {
    render(){
        return (
            <div className="loading-animation">
                <img src='/images/spinner.gif'  alt="loader" />
            </div>
        );
    }
}





/**
 * Renders simplified form elements using Bootstrap classes
 */
export class Form {
    static Input = FormInput;
}


