import React, {Component} from 'react';
import {Router} from 'react-router';
import {Route} from 'react-router-dom';
import {createBrowserHistory} from 'history'
import Models2 from "./components/Models2";
import Forside from "./components/Forside";
import Model2 from "./components/Model2";
import Menu from "./components/Menu";
import Hamburger from "./components/Hamburger";
import Instagram from "./components/Instagram";
import Agency from "./components/Agency";
import Search from "./components/Search";
import Savedfaces from "./components/Savedfaces";
import Savedfaces2 from "./components/Savedfaces2";
import SavedPDF from "./components/SavedPDF";
import Blimodell from "./components/Blimodell";
import Blimodell2 from "./components/Blimodell2";
import Privacy from "./components/Privacy";

import './css/menu.css';
import './css/gridlayout.css';
import './css/imagelayout.css';

const history = createBrowserHistory();

class App extends Component {
    constructor(props) {
        super(props);
        this.handler = this.handler.bind(this)
    }

    handler(someValue) {

    }


    handleToUpdate(someArg) {
        //  alert('We pass argument from Child to Parent: ' + someArg);
        this.setState({
            savedFaces: someArg
        })
    }


    hydrateStateWithLocalStorage() {
        // for all items in state
        for (let key in this.state) {
            // if the key exists in localStorage
            if (localStorage.hasOwnProperty(key)) {
                // get the key's value from localStorage
                let value = localStorage.getItem(key);

                // parse the localStorage string and setState
                try {
                    value = JSON.parse(value);
                    this.setState({[key]: value});
                } catch (e) {
                    // handle empty string
                    this.setState({[key]: value});
                }
            }
        }
    }


    render() {
        let handleToUpdate = this.handleToUpdate;

        if (!localStorage.getItem('savedFaces')) {
            localStorage.setItem("savedFaces", JSON.stringify([]));
            //  localStorage.removeItem(0);
            console.log("Savedface is empty, making a contentless shell")
        } else {
            console.log("Savedface not empty")
        }


        return (
            <div className="App">
                <div>
                    <Menu history={history} handler={this.handler}/>
                    <Hamburger history={history} handler={this.handler}/>
                    <div>
                        <div className="content">
                            <Router history={history}>
                                <Route
                                    exact
                                    path="/"
                                    render={(props) => <Forside {...props} />}
                                />
                                <Route
                                    exact
                                    path="/male/"
                                    render={(props) => <Models2 {...props} filter="men" heading="Men"
                                                                handleToUpdate={handleToUpdate.bind(this)}/>}
                                />
                                <Route
                                    exact
                                    path="/female/"
                                    render={(props) => <Models2 {...props} filter="women" heading="Women"
                                                                handleToUpdate={handleToUpdate.bind(this)}/>}
                                />
                                <Route
                                    exact
                                    path="/female/development/"
                                    render={(props) => <Models2 {...props} filter="newfaces" heading="New Faces"
                                                                handleToUpdate={handleToUpdate.bind(this)}/>}
                                />
                                <Route
                                    exact
                                    path="/female/mainboard/"
                                    render={(props) => <Models2 {...props} filter="w_fashion" heading="Fashion"
                                                                handleToUpdate={handleToUpdate.bind(this)}/>}
                                />
                                <Route
                                    exact
                                    path="/society/"
                                    render={(props) => <Instagram {...props} heading="Instagram"/>}
                                />
                                <Route
                                    exact
                                    path="/icon/men/"
                                    render={(props) => <Instagram {...props} filter="men" heading="Instagram"/>}
                                />
                                <Route
                                    exact
                                    path="/female/commercial/"
                                    render={(props) => <Models2 {...props} filter="w_model" heading="Classic"
                                                                handleToUpdate={handleToUpdate.bind(this)}/>}
                                />
                                <Route
                                    path="/female/youth/"
                                    render={(props) => <Models2 {...props} filter="c_girl" heading="Teens / kids"
                                                                handleToUpdate={handleToUpdate.bind(this)}/>}
                                />
                                <Route exact
                                       path="/male/development"
                                       render={(props) => <Models2 {...props} filter="m_newfaces" heading="New Faces"
                                                                   handleToUpdate={handleToUpdate.bind(this)}/>}
                                />
                                <Route exact
                                       path="/male/mainboard/"
                                       render={(props) => <Models2 {...props} filter="m_fashion" heading="Fashion"
                                                                   handleToUpdate={handleToUpdate.bind(this)}/>}
                                />
                                <Route exact
                                       path="/male/commercial/"
                                       render={(props) => <Models2 {...props} filter="m_model" heading="Classic"
                                                                   handleToUpdate={handleToUpdate.bind(this)}/>}
                                />
                                <Route exact
                                       path="/male/youth/"
                                       render={(props) => <Models2 {...props} filter="c_boy" heading="Teens / kids"
                                                                   handleToUpdate={handleToUpdate.bind(this)}/>}
                                />

                                <Route exact
                                       path="/models/:id"
                                       render={(props) => <Model2 {...props}
                                                                  handleToUpdate={handleToUpdate.bind(this)}/>}
                                />
                                <Route exact
                                       path="/selection/"
                                       render={(props) => <Savedfaces {...props} heading="Selection"
                                                                      handleToUpdate={handleToUpdate.bind(this)}/>}
                                />
                                <Route exact
                                       path="/savedpdf/"
                                       render={(props) => <SavedPDF {...props} heading="Selection"/>}
                                />
                                <Route exact
                                       path="/selection/:id"
                                       render={(props) => <Savedfaces2 {...props} heading="Selection"/>}
                                />

                                <Route exact
                                       path="/search/"
                                       render={(props) => <Search {...props} heading="Search"/>}
                                />

                                <Route exact
                                       path="/become/"
                                       render={(props) => <Blimodell {...props} />}
                                />
                                <Route exact
                                       path="/became/"
                                       render={(props) => <Blimodell2 {...props} />}
                                />
                                <Route exact
                                       path="/agency/"
                                       render={(props) => <Agency {...props} />}
                                />
                                <Route exact
                                       path="/privacy/"
                                       render={(props) => <Privacy {...props} />}
                                />

                            </Router>
                        </div>

                    </div>
                    <div className="footer-container">
                        <div className="footer-left">
                            Copyright &copy; <GetYear/> Pages by <a href="mailto:post&#64;trendmodels.no">Trend Models</a>
                        </div>
                        <div className="footer-right">
                            Pages by <a href="mailto:praxiz&#64;gmail.com">Øyvind Valstadsve.</a>
                        </div>

                    </div>
                    <div className="left">

                    </div>
                    <div className="right">

                    </div>
                </div>
            </div>
        );
    }
}

class GetYear extends React.Component {
    render() {
        return <span>{(new Date().getFullYear())}</span>;
    }
}

export default App;
