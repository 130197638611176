import React, {Component} from 'react';
import Container from 'react-bootstrap/Container'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Form from 'react-bootstrap/Form'


class Blimodell2 extends Component {
    render() {
        return (
            <div>
                <div className="blimod-form">
                    <Container>
                        <Form>
                            <Row>
                                <div className="blimodell-tekst pageheading">Your application has been submitted!</div>
                            </Row>
                            <Row>
                                <Col sm={4}>
                                    <Row>
                                        <div className="blimodell-tekst">
                                            <p>You will hear from us within two weeks, if we are interested in meeting
                                                you in person.</p>
                                        </div>
                                    </Row>
                                </Col>
                                <Col sm={4}>
                                </Col>
                                <Col sm={4}>
                                </Col>
                            </Row>
                        </Form>
                    </Container>
                </div>
            </div>
        )
    }
}


export default Blimodell2;