//@flow
import React, {Component} from "react";
import StackGrid from "react-stack-grid";
import Lightbox from "react-image-lightbox";
import {men_p, men_u, women_p, women_u} from "./widgets";

class Model2 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            addedToSavedFaces: false,
            data: null,
            isLoading: false,
            error: null,
            photoIndex: 0,
            isOpen: false,
            hits: [],
            images: [],

        }
    }

    componentDidMount() {
        this.loadData();

    }

    loadData() {

        let API = 'https://trend-api.trendmodels.no/backend/single2.php?ID=';
        let DEFAULT_QUERY = this.props.match.params.id;

        fetch(API + DEFAULT_QUERY)
            .then(response => response.json())
            .then(data =>this.setState({hits: data.records}));

        fetch(API + DEFAULT_QUERY)
            .then(response => response.json())
            .then(data => this.setState({images: data.records[0].modellbilder.records}));
    }


    renderImages = () => {
        let photoIndex = -1;
        // const {images} = this.state;
        // const {images2} = this.state;

        // console.log("images: ", images);
        // console.log("images2: ", images2);





        return this.state.hits[0].modellbilder.records.map (imgtest => {
            photoIndex++;
            const privateKey = photoIndex;
            // console.log("imgtest.filnavn -> ", imgtest);
            /// let find = "/modeller/" + this.props.match.params.id + "/medium";

            imgtest = imgtest.replace("medium", "thumbs")

            // console.log("imgtest: ", imgtest);
            return (
                <div key={photoIndex} className="model_img_container">
                    <img
                        src={"https://www.trendmodels.no" + imgtest}
                        alt="Modellimg"
                        className="model_img klikkbar"
                        onClick={() =>
                            this.setState({photoIndex: privateKey, isOpen: true})
                        }
                    />
                </div>
            );


        })
    };

    eleContainsInArray(arr, element) {

        if (arr != null && arr.length > 0) {
            for (let i = 0; i < arr.length; i++) {
                if (arr[i] === element)
                    console.log("Checking if ", arr[i], " equals " + element);
                return true;
            }
        }
        return false;
    }


    existsInSavedFaces(id) {
        let temp = JSON.parse(localStorage.getItem('savedFaces'));
        for (let key in temp) {
            if (temp[key] === id) {
                return true
            }
        }
        return false
    }


    removeFromSavedFaces(id) {
        let newSavedFaces = [];
        let temp = JSON.parse(localStorage.getItem('savedFaces'));

        for (let key in temp) {
            if (temp[key] !== id) {
                //     console.log(temp[key]);
                let newKey = Object.keys(newSavedFaces).length;
                //   console.log("Newkey: " + newKey)
                newSavedFaces[newKey] = temp[key];
            }
        }
        localStorage.setItem('savedFaces', JSON.stringify(newSavedFaces));

        let handleToUpdate = this.props.handleToUpdate;
        handleToUpdate(JSON.stringify(newSavedFaces))

    }


    addToSavedFaces(id) {
        console.log("localStorage.getItem('savedFaces') ==> " + localStorage.getItem('savedFaces'));
        let temp3 = JSON.parse(localStorage.getItem('savedFaces'));

        console.log(typeof temp3);
        console.log("temp3: ", temp3);

        let test = this.eleContainsInArray(localStorage.getItem('savedFaces'), id);
        console.log("test: " + test);

        let newKey = Object.keys(temp3).length;
        console.log("Newkey: " + newKey);
        temp3[newKey] = id;
        localStorage.setItem('savedFaces', JSON.stringify(temp3));
        console.log("localstorage: " + localStorage.getItem('savedFaces'));

        let handleToUpdate = this.props.handleToUpdate;
        handleToUpdate(JSON.stringify(temp3))
    }

    render() {
        function PickMenu(kategori) {
            switch (kategori) {
                case 'men':
                    return men_p;
                case 'women':
                    return women_p;
                case 'newfaces':
                    return women_u;
                case 'w_fashion':
                    return women_u;
                case 'w_model':
                    return women_u;
                case 'c_girl':
                    return women_u;
                default:
                    return men_u;
            }
        }

        if (!this.state.hits.length > 0) {
            return <div/>
        } else {
            const {photoIndex, isOpen, images, hits} = this.state;



            console.log("images: ", images)
            return (
                <div>
                    {hits.map((modell, index) => (
                        <span key={index}>
                                    {PickMenu(modell.type)}
                                </span>
                    ))}

                    <div className="modellkort-container">
                        <div className="model_name">
                            {hits.map((modell, index) => (
                                <span key={index}>
                                    {modell.fornavn} {modell.etternavn.charAt(0)}
                                </span>
                                ))}
                        </div>
                        <div className="model_info">
                            <div>
                                {hits.map((modell, index) => (
                                    <div key={index} className="cardstyle">

                                        {modell.hoyde  && (modell.type !== "c_boy" && modell.type !== "c_girl") ? <div className='card_info card_info-medium'>
                                            <div className="card-text">Height</div>
                                            <div className="card-bold">{modell.hoyde}</div>
                                        </div> : ''}
                                        {/*modell.jeans  && (modell.type !== "c_boy" && modell.type !== "c_girl") ? <div className='card_info card_info-medium'>
                                            <div className="card-text">Jeans</div>
                                            <div className="card-bold">{modell.jeans.toUpperCase()}</div>
                                        </div> : ''*/}
                                        {modell.klaer  && (modell.type !== "c_boy" && modell.type !== "c_girl") ? <div className='card_info card_info-medium'>
                                            <div className="card-text">Size</div>
                                            <div className="card-bold">{modell.klaer.toUpperCase()}</div>
                                        </div> : ''}
                                        {modell.sko  && (modell.type !== "c_boy" && modell.type !== "c_girl") ? <div className='card_info card_info-medium'>
                                            <div className="card-text">Shoes</div>
                                            <div className="card-bold">{modell.sko.toUpperCase()}</div>
                                        </div> : ''}
                                        {modell.haarfarge  && (modell.type !== "c_boy" && modell.type !== "c_girl") ? <div className='card_info card_info-medium'>
                                            <div className="card-text">Hair</div>
                                            <div className="card-bold">{modell.haarfarge.toUpperCase()}</div>
                                        </div> : ''}
                                        {modell.oyenfarge  && (modell.type !== "c_boy" && modell.type !== "c_girl") ? <div className='card_info card_info-medium'>
                                            <div className="card-text">Eyes</div>
                                            <div className="card-bold">{modell.oyenfarge.toUpperCase()}</div>
                                        </div> : ''}
                                        {modell.fodt && (modell.type === "c_boy" || modell.type === "c_girl" ) ? <div className='card_info card_info-medium'>
                                            <div className="card-text">Born</div>
                                            <div className="card-bold">{modell.fodt.toString().toUpperCase()}</div>
                                        </div> : ''}


                                        {/*modell.bhcup ? <div className='card_info card_info-medium'>
                                        <div className="card-bold">Bra-cup:</div>
                                        <div className="card-text">{modell.bhcup.toUpperCase()}</div>
                                    </div> : ''}
                                        {modell.byste ? <div className='card_info card_info-medium'>
                                            <div className="card-bold">Bust:</div>
                                            <div className="card-text">{modell.byste.toUpperCase()}</div>
                                        </div> : ''}
                                        {modell.midje ? <div className='card_info card_info-medium'>
                                            <div className="card-bold">Waist:</div>
                                            <div className="card-text">{modell.midje.toUpperCase()}</div>
                                        </div> : ''}
                                        { modell.hofte ? <div className='card_info card_info-medium'>
                                            <div className="card-bold">Hips:</div>
                                            <div className="card-text">{modell.hofte.toUpperCase()}</div>
                                        </div> : ''*/}

                                        <div className="blackline">&nbsp;</div>


                                        {modell.instagram ?
                                            <div className="visitoninstagram">
                                                <a href={modell.instagram} target="_blank"
                                                   rel="noopener noreferrer">
                                                    INSTAGRAM
                                                </a>
                                            </div>
                                            : ''}


                                        {
                                            (
                                                this.existsInSavedFaces(modell.ID) ?
                                                    <div className="addtosavedfaces klikkbar noselect" onClick={() => {
                                                        this.removeFromSavedFaces(modell.ID)
                                                    }}>Remove Selection</div>
                                                    :
                                                    <div className="addtosavedfaces klikkbar noselect" onClick={() => {
                                                        this.addToSavedFaces(modell.ID)
                                                    }}>Add to Selection</div>
                                            )}



                                        {modell.fly === '1' ?
                                            <div className="fly">
                                                <img src="/images/fly.jpg" alt="Fly-ikon"/>
                                            </div>
                                            : ''}

                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className="model_images">
                                <StackGrid
                                    gridRef={grid => this.grid = grid}
                                    itemComponent="div"
                                    monitorImagesLoaded={true}
                                    columnWidth={300}
                                    gutterWidth={7}
                                >

                                    {this.renderImages()}

                                </StackGrid>
                                {isOpen && (
                                    <Lightbox
                                        mainSrc={images[photoIndex]}
                                        nextSrc={images[(photoIndex + 1) % images.length]}
                                        prevSrc={images[(photoIndex + images.length - 1) % images.length]}
                                        imageTitle={photoIndex + 1 + "/" + images.length}
                                        onCloseRequest={() => this.setState({isOpen: false})}
                                        onMovePrevRequest={() =>
                                            this.setState({
                                                photoIndex: (photoIndex + images.length - 1) % images.length
                                            })
                                        }
                                        onMoveNextRequest={() =>
                                            this.setState({
                                                photoIndex: (photoIndex + 1) % images.length
                                            })
                                        }
                                    />
                                )}
                        </div>
                    </div>


                </div>
            )
        }
    }
}

export default Model2;
