import React, {Component} from "react";
import Container from 'react-bootstrap/Container'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'

class Agency extends Component {
    render() {
        return (
            <div>
                <div>&nbsp;</div>
                <Container>
                    <Row>
                        <Col sm={1}></Col>
                        <Col sm={5}>
                            <p className="agency">Trend Models was founded in 1992 by Gry Sæther. Today, it is the leading modelling agency of Central Norway, with nearly 30 years of experience working with well-known actors in
                                both local and international markets.</p>
                            <p className="agency">As an agency, we aim to better the modelling industry in terms of beauty ideals and body
                            images. Trend Models believes in beauty in diversity. Therefore, we represent a wide range
                            of models in all ages, sizes and colors. It is models who each and every one can recognize
                                themselves in. They are healthy and beautiful people with strong and inspiring personalities.</p>

                            <p className="agency">We believe we represent and form models that are great role models in the society.</p><br/>
                           <p className="agency"><i>
                               Email: <a href="mailto:post@trendmodels.no">post@trendmodels.no</a><br/>
                            Phone: +47 73 51 33 11<br/>
                               Address: Mellomila 56, 7018 Trondheim, Norway<br/></i></p>
                        </Col>
                        <Col sm={5}>
                            <img className="agencyimage" src="/images/agency.jpg" alt="Trend models agency information"/>
                        </Col>
                        <Col sm={1}></Col>
                    </Row>
                </Container>
            </div>
        );
    }
}

export default Agency;