import React, {Component} from 'react';
import Container from 'react-bootstrap/Container'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Form from 'react-bootstrap/Form'
import {Link} from "react-router-dom"

class Blimodell extends Component {
    constructor(props) {
        super(props);
        this.state = {hightlight1: false};
        this.state = {hightlight2: false};
        this.state = {hightlight3: false};
        this.state = {hightlight4: false};
        this.fileInputRef = React.createRef();

        this.postModel = this.postModel.bind(this);
        this.yearlist = [];

        this.openFileDialog = this.openFileDialog.bind(this);
        this.onFilesAdded = this.onFilesAdded.bind(this);

        this.onDragOver1 = this.onDragOver1.bind(this);
        this.onDragLeave1 = this.onDragLeave1.bind(this);
        this.onDragOver2 = this.onDragOver2.bind(this);
        this.onDragLeave2 = this.onDragLeave2.bind(this);
        this.onDragOver3 = this.onDragOver3.bind(this);
        this.onDragLeave3 = this.onDragLeave3.bind(this);
        this.onDragOver4 = this.onDragOver4.bind(this);
        this.onDragLeave4 = this.onDragLeave4.bind(this);

        this.onDrop = this.onDrop.bind(this);
        this.state = {
            checked: [],
            portrait1: "/images/blimod1.jpg",
            portrait2: "/images/blimod2.jpg",
            portrait3: "/images/blimod4.jpg",
            portrait4: "/images/blimod3.jpg",
            errormsg: "",
        };
    }


    postModel() {
        console.log("fornavn: ", this.state.fornavn);
        console.log("etternavn: ", this.state.etternavn);
        console.log("fodt: ", this.state.fodt);


        let url = 'https://www.trendmodels.no/blimodell/new.php';
        let data = {
            fornavn: this.state.fornavn,
            etternavn: this.state.etternavn,
            fodt: this.state.fodt,
            adresse: this.state.adresse,
            postnr: this.state.postnr,
            poststed: this.state.poststed,
            telefon: this.state.telefon,
            mobil: this.state.mobil,
            email: this.state.email,
            hoyde: this.state.hoyde,
            sko: this.state.sko,
            bilde1: this.state.portrait1,
            bilde2: this.state.portrait2,
            bilde3: this.state.portrait3,
            bilde4: this.state.portrait4,
            clicked: ""
        };

        fetch(url, {
            method: 'POST', // or 'PUT'
            body: JSON.stringify(data), // data can be `string` or {object}!
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(res => res.json())
            .then(response => {
                console.log("Success: ", response.hurra);
                console.log("Response: ", response);

                if (response !== "Must have both names, year of birth, e-mail and mobile number.") {
                    this.props.history.push("/became/")
                } else {


                    this.setState({
                        errormsg: response
                    })
                }

                // let nyURL = ('#/edit/' + response.nyId);
                //this.props.history.push(nyURL);
                //window.location.reload();
            })
            .catch(error => console.error('Error:', error));
    }

    successfulPost() {

    }

    componentDidMount() {

    }


    uploadFile(files, angle) {

        let formData = new FormData();
        const files_array = Array.from(files);

        console.log("Angle: ", angle);

        files_array.map((file, index) => {
            // console.log(index)
            formData.append('file', file);
            return formData
        });
        console.log("formdata: ", formData);

        fetch('https://www.trendmodels.no/blimodell/blimodellupload.php?ID=1383', {
            // content-type header should not be specified!
            method: 'POST',
            body: formData,


        })
            .then(response => response.json())
            .then(success => {
                console.log("Success: ", success);

                if ((angle === "portrettbilde") && (success.status === "success")) {
                    this.setState({
                        portrait1: "https://www.trendmodels.no/blimodell/upload/" + success.filename
                    })
                }
                if ((angle === "profilbilde") && (success.status === "success")) {
                    this.setState({
                        portrait2: "https://www.trendmodels.no/blimodell/upload/" + success.filename
                    })
                }
                if ((angle === "frontfigur") && (success.status === "success")) {
                    this.setState({
                        portrait3: "https://www.trendmodels.no/blimodell/upload/" + success.filename
                    })
                }
                if ((angle === "sidefigur") && (success.status === "success")) {
                    this.setState({
                        portrait4: "https://www.trendmodels.no/blimodell/upload/" + success.filename
                    })
                }
            })
            .catch(error => console.log(error)
            );
    }

    openFileDialog = event => {

        if (this.props.disabled) return;

        if (event.target.name !== "") {
            console.log("clicked openFileDialog: ", event.target.name)
            this.setState({clicked: event.target.name})
        }
        this.fileInputRef.current.click(event, event.target.name)
    };

    onFilesAdded(evt) {
        if (this.props.disabled) return;
        let files = evt.target.files;
        // console.log("angle: ", evt.target.name)
        // console.log("angle: ", this.state.clicked)
        this.uploadFile(files, this.state.clicked);
        if (this.props.onFilesAdded) {
            const array = this.fileListToArray(files);
            this.props.onFilesAdded(array)
        }
        // this.uploadFile(files, evt.target.name)
    }

    onDragOver1(evt) {
        evt.preventDefault();
        if (this.props.disabled) return;
        this.setState({hightlight1: true})
    }

    onDragOver2(evt) {
        evt.preventDefault();
        if (this.props.disabled) return;
        this.setState({hightlight2: true})
    }

    onDragOver3(evt) {
        evt.preventDefault();
        if (this.props.disabled) return;
        this.setState({hightlight3: true})
    }

    onDragOver4(evt) {
        evt.preventDefault();
        if (this.props.disabled) return;
        this.setState({hightlight4: true})
    }

    onDragLeave1() {
        this.setState({hightlight1: false})
    }

    onDragLeave2() {
        this.setState({hightlight2: false})
    }

    onDragLeave3() {
        this.setState({hightlight3: false})
    }

    onDragLeave4() {
        this.setState({hightlight4: false})
    }

    onDrop(event) {
        event.preventDefault();
        if (this.props.disabled) return;

        const files = event.dataTransfer.files;
        console.log("onDrop: ", files);

        this.uploadFile(files, event.target.name);
        if (this.props.onFilesAdded) {
            const array = this.fileListToArray(files);
            this.props.onFilesAdded(array)
        }
        this.setState({hightlight: false})
        //
    }


    handleChange = e => {
        const target = e.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.id;
        console.log("Adding: ", name, " : ", value);
        this.setState(() => ({
            [name]: value
        }));

        // console.log(this.state);
    };


    fileListToArray(list) {
        const array = [];
        for (let i = 0; i < list.length; i++) {
            array.push(list.item(i))
        }
        return array
    }

    render() {

        let maxOffset = 110;
        let thisYear = (new Date()).getFullYear();
        let allYears = [];
        for (let x = 0; x <= maxOffset; x++) {
            allYears.push(thisYear - x)
        }

        const yearList = allYears.map((x) => {
            return (<option value={x} key={x}>{x}</option>)
        });

        return (
            <div>
                <div className="blimod-form">

                    <Container>

                        <Form>
                            <Row>
                                <div className="blimodell-tekst pageheading">Become a model?</div>
                            </Row>
                            <Row>
                                <Col sm={4}>

                                    <Row>
                                        <div className="blimodell-tekst">


                                            <p><b>Trend Models are always looking for new faces to work with!</b></p>

                                            <p>We are looking for:</p>


                                            <p>
                                                <b>Female Models</b><br/>
                                                Age: 14 - 22 years old<br/>
                                                Height: 170 - 182cm<br/>
                                            </p>

                                            <p>
                                                <b>Male Models</b><br/>
                                                Age: 16 - 26 years old<br/>
                                                Height: 180 - 190cm<br/>
                                            </p>


                                            <p><b>Youth & Commercial Models</b><br/>

                                                People in all ages, sizes and looks who enjoy being in front of the
                                                camera.</p>


                                            <p>Please send us information about yourself and newly taken photos using
                                                the form to the right OR send an email to <a
                                                    href="mailto:post&#64;trendmodels.no">post&#64;trendmodels.no</a>.
                                            </p>


                                            <p>You will hear from us within two weeks, if we are interested in meeting
                                                you in person.</p>

                                            <p>We look forward to hearing from you!</p>

                                            <p>By submitting this form, you agree to have read our <Link to="/privacy">privacy
                                                policy</Link>.</p>
                                        </div>
                                        {/*<div className="smallboldtext"><u>Underlined</u> fields are
                                            required.
                                        </div>*/}
                                    </Row>

                                </Col>
                                <Col sm={4}>
                                    <Container>
                                        <Row>
                                            <div>&nbsp;</div>
                                        </Row>
                                        <Row>
                                            <Col sm={12}>

                                                <Form.Group as={Row} controlId="fornavn" onChange={this.handleChange}>
                                                    <Form.Label column sm="4">
                                                        <>First name</>
                                                    </Form.Label>
                                                    <Col sm="7">
                                                        <Form.Control/>
                                                    </Col>
                                                </Form.Group>

                                                <Form.Group as={Row} controlId="etternavn" onChange={this.handleChange}>
                                                    <Form.Label column sm="4">
                                                        <>Surname</>
                                                    </Form.Label>
                                                    <Col sm="7">
                                                        <Form.Control/>
                                                    </Col>
                                                </Form.Group>

                                                <Form.Group as={Row} controlId="fodt" onChange={this.handleChange}>
                                                    <Form.Label column sm="4"><>Birthyear</>
                                                    </Form.Label>
                                                    <Col sm="7">

                                                        <Form.Control as="select" onChange={this.handleTemplateChange}>>
                                                            <option>Choose...</option>
                                                            {yearList}
                                                        </Form.Control>

                                                    </Col>
                                                </Form.Group>

                                                <Form.Group as={Row} controlId="adresse" onChange={this.handleChange}>
                                                    <Form.Label column sm="4">
                                                        Address
                                                    </Form.Label>
                                                    <Col sm="7">
                                                        <Form.Control/>
                                                    </Col>
                                                </Form.Group>
                                                <Form.Group as={Row} controlId="postnr" onChange={this.handleChange}>
                                                    <Form.Label column sm="4">
                                                        Postal code
                                                    </Form.Label>
                                                    <Col sm="5">
                                                        <Form.Control/>
                                                    </Col>
                                                </Form.Group>
                                                <Form.Group as={Row} controlId="poststed" onChange={this.handleChange}>
                                                    <Form.Label column sm="4">
                                                        City
                                                    </Form.Label>
                                                    <Col sm="7">
                                                        <Form.Control/>
                                                    </Col>
                                                </Form.Group>

                                                <Form.Group as={Row} controlId="mobil" onChange={this.handleChange}>
                                                    <Form.Label column sm="4">
                                                        <>Cell phone</>
                                                    </Form.Label>
                                                    <Col sm="7">
                                                        <Form.Control/>
                                                    </Col>
                                                </Form.Group>
                                                <Form.Group as={Row} controlId="email" onChange={this.handleChange}>
                                                    <Form.Label column sm="4">
                                                        <>E-mail</>
                                                    </Form.Label>
                                                    <Col sm="7">
                                                        <Form.Control/>
                                                    </Col>
                                                </Form.Group>
                                                <Form.Group as={Row} controlId="hoyde" onChange={this.handleChange}>
                                                    <Form.Label column sm="4">
                                                        Height (cm)
                                                    </Form.Label>
                                                    <Col sm="5">
                                                        <Form.Control/>
                                                    </Col>
                                                </Form.Group>
                                                <button type="button" onClick={this.postModel}
                                                        className="btn custom-submitbutton">SUBMIT
                                                </button>

                                                <div>
                                                    <h5><p>&nbsp;</p>{this.state.errormsg}</h5>
                                                </div>

                                            </Col>
                                        </Row>
                                    </Container>
                                </Col>
                                <Col sm={4}>
                                    <div className="blimodell-container">

                                        <div className="box">
                                            <div
                                                className={`Dropzone1 ${this.state.hightlight1 ? 'Highlight' : ''}`}
                                                onDragOver={this.onDragOver1}
                                                onDragLeave={this.onDragLeave1}
                                                onDrop={this.onDrop}
                                                onClick={this.openFileDialog}
                                                style={{cursor: this.props.disabled ? 'default' : 'pointer'}}
                                            >
                                                <input
                                                    ref={this.fileInputRef}
                                                    className="FileInput"
                                                    type="file"
                                                    multiple
                                                    onChange={this.onFilesAdded}
                                                />
                                                <img
                                                    alt="upload"
                                                    className="Icon"
                                                    name="portrettbilde"
                                                    src={this.state.portrait1}
                                                />

                                            </div>
                                            <span
                                                className="full-width-div some-padding-div centered">Portrettbilde</span>
                                        </div>
                                        <div className="box">
                                            <div
                                                className={`Dropzone2 ${this.state.hightlight2 ? 'Highlight' : ''}`}
                                                onDragOver={this.onDragOver2}
                                                onDragLeave={this.onDragLeave2}
                                                onDrop={this.onDrop}
                                                onClick={this.openFileDialog}
                                                style={{cursor: this.props.disabled ? 'default' : 'pointer'}}
                                            >
                                                <input
                                                    ref={this.fileInputRef}
                                                    className="FileInput"
                                                    type="file"
                                                    multiple
                                                    onChange={this.onFilesAdded}
                                                />
                                                <img
                                                    alt="upload"
                                                    name="profilbilde"
                                                    className="Icon"
                                                    src={this.state.portrait2}
                                                />

                                            </div>
                                            <span
                                                className="full-width-div some-padding-div centered">Profilbilde</span>
                                        </div>
                                        <div className="box">
                                            <div
                                                className={`Dropzone3 ${this.state.hightlight3 ? 'Highlight' : ''}`}
                                                onDragOver={this.onDragOver3}
                                                onDragLeave={this.onDragLeave3}
                                                onDrop={this.onDrop}
                                                onClick={this.openFileDialog}
                                                style={{cursor: this.props.disabled ? 'default' : 'pointer'}}
                                            >
                                                <input
                                                    ref={this.fileInputRef}
                                                    className="FileInput"
                                                    type="file"
                                                    multiple
                                                    onChange={this.onFilesAdded}
                                                />
                                                <img
                                                    alt="upload"
                                                    className="Icon"
                                                    name="frontfigur"
                                                    src={this.state.portrait3}
                                                />

                                            </div>
                                            <span
                                                className="full-width-div some-padding-div centered">Helfigur - front</span>
                                        </div>
                                        <div className="box">
                                            <div
                                                className={`Dropzone4 ${this.state.hightlight4 ? 'Highlight' : ''}`}
                                                onDragOver={this.onDragOver4}
                                                onDragLeave={this.onDragLeave4}
                                                onDrop={this.onDrop}
                                                onClick={this.openFileDialog}
                                                style={{cursor: this.props.disabled ? 'default' : 'pointer'}}
                                            >
                                                <input
                                                    ref={this.fileInputRef}
                                                    className="FileInput"
                                                    type="file"
                                                    multiple
                                                    onChange={this.onFilesAdded}
                                                />
                                                <img
                                                    alt="upload"
                                                    name="sidefigur"
                                                    className="Icon"
                                                    src={this.state.portrait4}
                                                />

                                            </div>
                                            <span className="full-width-div some-padding-div centered">Helfigur - fra siden</span>
                                        </div>
                                        <div>Drag and drop images to the boxes or click them to select an image</div>
                                    </div>

                                </Col>

                            </Row>
                        </Form>
                    </Container>


                </div>
            </div>
        )
    }
}


export default Blimodell;
