import React, {Component} from 'react';

class Menu extends Component <{ match: { params: { id: number } } }> {
    constructor(props) {
        super(props);
        this.state = {
            hits: []
        }
    }


    render() {
        // console.log("Localstorage: ", localStorage.getItem('savedFaces'));
        // console.log(this.props.savedfaces)

        return (
            <div>

                <div className="menu-grid-container">
                    <div className="menu-left-menu">

                    </div>
                    <div className="menu-socialmedia">

                        <a href="https://www.facebook.com/Trend-Models-275968589084835/" target="_blank"
                           rel="noopener noreferrer"><img src="/images/facebooklogo.png" className="socialmedialogo"
                                                          alt="Facebook logo"/></a>
                        <a href="https://www.instagram.com/trendmodels/" target="_blank"
                           rel="noopener noreferrer"><img src="/images/instagramlogo.png"
                                                          className="socialmedialogo" alt="Instagram logo"/></a>

                    </div>
                    <div className="menu-logo">
                        <a href="/">
                            <img src="/images/logo2.png" className="logoimg" alt="Trend models logo"/>
                        </a>
                    </div>
                    <div className="menu-extrabuttons">

                        <button type="button" className="small-link fakedropdown" onClick={(event) => {
                            this.props.history.push("/search");
                        }}>SEARCH
                        </button>
                        <button type="button" className="small-link fakedropdown" onClick={(event) => {
                            this.props.history.push("/selection");
                        }}>SELECTION ({Object.keys(JSON.parse(localStorage.getItem('savedFaces'))).length})
                        </button>
                    </div>
                    <div className="menu-right-menu"></div>
                    <div className="menu-mainmenu">

                        <div className="menu-container">
                            <div className="a1">
                                <button type="button" className="btn fakedropdown" onClick={(event) => {
                                    this.props.history.push("/female");
                                }}>FEMALE
                                </button>
                            </div>
                            <div className="a2">

                                <button type="button" className="btn fakedropdown" onClick={(event) => {
                                    this.props.history.push("/male");
                                }}>MALE
                                </button>
                            </div>
                            <div className="a3">
                                <button type="button" className="btn fakedropdown" onClick={(event) => {
                                    this.props.history.push("/society");
                                }}>SOCIETY
                                </button>
                            </div>
                            <div className="a4">
                                <button type="button" className="btn fakedropdown" onClick={(event) => {
                                    this.props.history.push("/agency");
                                }}>AGENCY
                                </button>
                            </div>
                            <div className="a5">
                                <button type="button" className="btn fakedropdown" onClick={(event) => {
                                    this.props.history.push("/become");
                                }}>BECOME
                                </button>
                            </div>
                        </div>

                    </div>
                </div>








            </div>
        );
    }
}


export default Menu;
