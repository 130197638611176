//@flow
import React, {Component} from "react";
import StackGrid from "react-stack-grid";
import {
    Loading
} from "./widgets";
import {Link} from "react-router-dom";

class Instagram extends Component<{
    match: { params: { id: number } }
}> {
    constructor(props) {
        super(props);
        this.state = {
            hits: [],
            loading: true,
            ig_info: []
        }

    }

    componentDidMount() {
 const API = 'https://trend-api.trendmodels.no/backend/instagram.php';

            console.log("URL: " + API);
            fetch(API)
                .then(response => response.json())
                .then(data => this.setState({hits: data.records}));

    }

    render() {
        let lists;
        const {hits} = this.state;

        let thisPath = this.props.location.pathname;
        thisPath.indexOf(1);
        thisPath = thisPath.split("/")[1];
        console.log("Path: ", thisPath);


        if ((hits.length === 0) && (this.props.filter !== "men") && (this.props.filter !== "women")) {
            lists = (
                <Loading/>
            );
        } else {

            console.log("ig-info: ", this.state.ig_info);

            lists = (
                <StackGrid
                    gridRef={grid => this.grid = grid}
                    itemComponent="div"
                    monitorImagesLoaded={false}

                    columnWidth={300}
                    gutterWidth={7}
                >
                    {hits.map(casen => (
                        <div key={casen.ID}>
                            <div className="profilbilde_kontainer">
                                <Link to={"/models/" + casen.ID}>
                                <img
                                    src={"https://www.trendmodels.no/modeller/" + casen.ID + "/thumbs/" + casen.filnavn}
                                    alt={casen.fornavn}
                                    className="profilbilde"
                                /></Link></div>
                            <div className="profilbilde_tekst">
                                {casen.fornavn} {casen.etternavn.charAt(0)}
                            </div>

                            <div className="socialmedia">
                                <div className="social-icon social-icon--instagram">&nbsp;</div>
                                <div className="followernumber">{casen.igfollowers}</div>
                            </div>

                        </div>
                    ))}
                </StackGrid>
            );


        }


        if (!this.loading) {
            return (
                <div>

                    <div className="pageheading">&nbsp;</div>

                    <div className="parent">
                        {lists}
                    </div>
                    <div className="col-md-2">&nbsp;</div>
                </div>
            );
        } else {
            return <Loading/>;
        }
    }

}

//


export default Instagram;
