//@flow
import React, { Component } from "react";
import StackGrid from "react-stack-grid";
import {Link} from "react-router-dom"
import {
    Loading
} from "./widgets";



class Forside extends Component {
    constructor(props) {
        super(props);
        this.state = {
            hits: [],
            width: 0,
            height: 0
        };

        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    }


    componentDidMount() {
        const API = 'https://trend-api.trendmodels.no/backend/nyforside.php';


        fetch(API)
            .then(response => response.json())
            .then(data => this.setState({ hits: data.records }));

        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);
    }
    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
    }

    updateWindowDimensions() {
        this.setState({ width: window.innerWidth, height: window.innerHeight });
        // console.log("this.state.width: " + this.state.width)
    }
    render() {
        let lists;
        let columnwidth;
        this.state.width > 600 ? (columnwidth = 400) : (columnwidth = 150);
        const { hits } = this.state;
            lists = (
                <StackGrid
                    gridRef={grid => this.grid = grid}
                    itemComponent = "div"
                    monitorImagesLoaded = {true}
                    columnWidth={columnwidth}
                    gutterWidth={15}
                >
                {hits.map(casen => (
                        <div key={casen.ID} className="forsidebilde-container">
                            <Link to={"/models/" + casen.modellID}><img
                                src={"https://www.trendmodels.no/modeller/" + casen.modellID + "/" + casen.filnavn}
                                alt={casen.fornavn}
                                className="modelimgdetail"
                            /></Link>
                            <div className="previewImage_container">
                                <div className="title-preview">
                                    <span className="fp-modellnavn">{casen.modellNavn}</span>{" "}

                                    <span className="fp-publikasjon">{casen.publikasjon}</span>{" "}

                                    <span className="fp-fotograf">{casen.fotograf}</span>
                                </div>
                            </div>
                        </div>
                ))}
                </StackGrid>
            );


        if (!this.loading) {
            return (
                <div className="forsiden">


                    {lists}


                </div>
            );
        } else {
            return <Loading />;
        }
    }
}

//


export default Forside;
