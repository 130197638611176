import React, {Component} from "react";
import {Loading} from "./widgets";
import { PDFDownloadLink, Document, Page, View, Text, Font, Image, Link} from '@react-pdf/renderer'
import Hashids from "hashids/lib/hashids";
import {CopyToClipboard} from 'react-copy-to-clipboard';


class Savedfaces2 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            hits: [],
            loading: true,
            url: "",
            text_to_copy: "https://www.trendmodels.no" + this.props.location.pathname,
            copied: false,
        }
    }

    onCopy = () => {
        this.setState({copied: true});
    };

    componentDidMount() {
        this.setState({hits: JSON.parse(localStorage.getItem('savedFaces'))});
        this.loadData();
        this.setState({loading: false});
    }


    loadData() {

        let API = 'https://trend-api.trendmodels.no/backend/savedfaces.php';

        let hashids = new Hashids();

        console.log("coded: " + this.props.match.params.id);


        let decodedquery2 =  ("[" + hashids.decode(this.props.match.params.id) + "]");
        decodedquery2 = JSON.parse(decodedquery2);

        console.log("decodedquery2: " + decodedquery2);

        let DEFAULT_QUERY = "?";
        for (var key in decodedquery2) {
            DEFAULT_QUERY += ("m[]=" + decodedquery2[key] + "&");
        }

        fetch(API + DEFAULT_QUERY)
            .then(response => response.json())
            .then(data => this.setState({hits: data.records}));
    }

    addToSavedFaces(id) {
        let temp3 = JSON.parse(localStorage.getItem('savedFaces'));
        temp3[Object.keys(temp3).length] = id;
        localStorage.setItem('savedFaces', JSON.stringify(temp3));
        // console.log("localstorage: " + localStorage.getItem('savedFaces'))
    }

    removeFromSavedFaces(id) {
        let newSavedFaces = [];
        console.log("Removing " + id);
        let temp = JSON.parse(localStorage.getItem('savedFaces'));

        for (let key in temp) {
            if (temp[key] !== id) {

                let newKey = Object.keys(newSavedFaces).length;
                //   console.log("Newkey: " + newKey)
                newSavedFaces[newKey] = temp[key];
            }
        }
        localStorage.setItem('savedFaces', JSON.stringify(newSavedFaces));

    }

    render() {
        let lists;

        const { hits, loading } = this.state;
        const styles = {
            text: {
                fontSize:22,
                fontFamily: 'Oswald',
                color: '#212121',
            },
            text2: {
                fontSize:12,
                fontFamily: 'Oswald',
                color: '#212121',
            },
            link: {
                fontSize:14,
                fontFamily: 'Oswald',
                color: '#000044',
            },
            image: {
                width: '200',
                padding: 0,
                align: 'left',
                overflow: 'hidden',
                left:0,
                top:0,
                right:0,
                bottom:0
            },
            page: {
                padding: 20,

            },
            box: { width: '100%', marginBottom: 30, borderRadius: 5 },
            box2: {
                position: 'absolute',
                left: 0,
            },
            box3: {
                position: 'absolute',
                left: 210,
            },
            pageNumbers: {
                position: 'absolute',
                bottom:10,
                textAlign: 'center',
                fontSize: 10,
                color: 'grey',
            },
        };
        Font.register({
            family: 'Oswald',
            src: 'https://fonts.gstatic.com/s/oswald/v13/Y_TKV6o8WovbUd3m_X9aAA.ttf',
        });

        console.log("hits: ", hits);

        let year = new Date().getFullYear();
        let month = new Date().getMonth()+1;
        let day = new Date().getDate();

        const doc = (
            <Document>

                <Page style={styles.page} size="A4" wrap="true" orientation="portrait">
                    {hits.map(pdfdata => (

                        <View key={pdfdata.ID} style={[styles.box, {height: 380, backgroundColor: 'white'}]}>
                            <Text style={styles.text2}>
                                First page is blank for some reason.
                            </Text>
                            <View style={styles.box2}>
                                <Link style={styles.link} src={"https://www.trendmodels.no/models/" + pdfdata.ID}><Image
                                    style={styles.image}
                                    src={"/modeller/" + pdfdata.ID + "/thumbs/" + pdfdata.filnavn}/></Link>
                            </View>
                            <View style={styles.box3}>
                                <Text style={styles.text}>
                                    {pdfdata.fornavn} {pdfdata.etternavn}
                                </Text>
                                <Text style={styles.text2}>
                                    Height: {pdfdata.hoyde}
                                </Text>
                                <Text style={styles.text2}>
                                    Size: {pdfdata.klaer}
                                </Text>
                                <Text style={styles.text2}>
                                    Shoes: {pdfdata.sko}
                                </Text>
                                <Text style={styles.text2}>
                                    Hair: {pdfdata.haarfarge}
                                </Text>
                                <Text style={styles.text2}>
                                    Eyes: {pdfdata.oyenfarge}
                                </Text>
                                <Text style={styles.text2}>
                                    Born: {pdfdata.born}
                                </Text>
                                <Text>
                                    <Link style={styles.link} src={"https://www.trendmodels.no/models/" + pdfdata.ID}>Click
                                        here to view more details</Link>
                                </Text>
                            </View>
                        </View>
                    ))}

                    <Text style={styles.pageNumbers} fixed>
                        Trend Models AS - 7018 Trondheim, Norway (c) {year}
                    </Text>
                </Page>
            </Document>
        );


        lists = (

                <div className="si-containercontainer">
                    {hits.map(modell => (

                            <div className="si-grid-container" key={modell.ID} >
                                <div className="si-left">&nbsp;</div>
                                <div className="si-image">

                                    <img
                                        src={"http://www.trendmodels.no/modeller/" + modell.ID + "/thumbs/" + modell.filnavn}
                                        alt={modell.fornavn}
                                        className="si-modelimg"
                                        onClick={() => {
                                            this.props.history.push("/models/" + modell.ID)
                                        }}
                                    />




                                </div>
                                <div className="si-navn">{modell.fornavn} {modell.etternavn}</div>

                                <div className="si-details">

                                    {modell.hoyde && (modell.type !== "c_boy" && modell.type !== "c_girl") ?
                                        <div className='card_info card_info-medium'>
                                            <div className="card-text">Height</div>
                                            <div className="card-bold">{modell.hoyde}</div>
                                        </div> : ''}
                                    {/*modell.jeans && (modell.type !== "c_boy" && modell.type !== "c_girl") ?
                                        <div className='card_info card_info-medium'>
                                            <div className="card-text">Size</div>
                                            <div className="card-bold">{modell.jeans.toUpperCase()}</div>
                                        </div> : ''*/}
                                    {modell.klaer && (modell.type !== "c_boy" && modell.type !== "c_girl") ?
                                        <div className='card_info card_info-medium'>
                                            <div className="card-text">Size</div>
                                            <div className="card-bold">{modell.klaer.toUpperCase()}</div>
                                        </div> : ''}
                                    {modell.sko && (modell.type !== "c_boy" && modell.type !== "c_girl") ?
                                        <div className='card_info card_info-medium'>
                                            <div className="card-text">Shoes</div>
                                            <div className="card-bold">{modell.sko.toUpperCase()}</div>
                                        </div> : ''}
                                    {modell.haarfarge && (modell.type !== "c_boy" && modell.type !== "c_girl") ?
                                        <div className='card_info card_info-medium'>
                                            <div className="card-text">Hair</div>
                                            <div className="card-bold">{modell.haarfarge.toUpperCase()}</div>
                                        </div> : ''}
                                    {modell.oyenfarge && (modell.type !== "c_boy" && modell.type !== "c_girl") ?
                                        <div className='card_info card_info-medium'>
                                            <div className="card-text">Eyes</div>
                                            <div className="card-bold">{modell.oyenfarge.toUpperCase()}</div>
                                        </div> : ''}
                                    {modell.fodt && (modell.type === "c_boy" || modell.type === "c_girl") ?
                                        <div className='card_info card_info-medium'>
                                            <div className="card-text">Born</div>
                                            <div className="card-bold">{modell.fodt.toUpperCase()}</div>
                                        </div> : ''}
                                </div>
                                <div className="si-right">&nbsp;</div>

                            </div>


                        )

                    )}
                </div>
        );

        let thisPath = this.props.location.pathname;

        let thisURL = "https://www.trendmodels.no" + thisPath
        thisPath = thisPath.substring(0, thisPath.lastIndexOf("/") + 1)



        if (!loading) {
            return (
                <div>
                    <div className="centered pageheading">
                        {this.props.heading}
                    </div>
                    <div>
                        <div className="a5">
                            <button type="button" onClick = {() => this.props.history.push(thisPath)} className="btn fakedropdown">BACK TO SAVED FACES</button>
                            <PDFDownloadLink document={doc} fileName={"Trendmodels_Suggestions_" + day + "-" + month + "-" + year + ".pdf"}>
                                {({ blob, url, loading }) => (loading ? 'Loading document...' : <button type="button" className="btn fakedropdown">SAVE AS PDF</button>)}
                            </PDFDownloadLink>
                        </div>
                        <div className="a5">&nbsp;</div>
                        <div className="a5">
                            <p>This URL will now show these models:</p>
                            <p>{thisURL}</p>
                            <p>
                                <CopyToClipboard onCopy={this.onCopy} text={this.state.text_to_copy}>
                                    <button>Copy to clipboard</button>
                                </CopyToClipboard>
                                {this.state.copied ? <span style={{color: 'black'}}><br/>Copied!</span> : null}
                            </p>
                        </div>


                    </div>


                    <div id="modellkort">
                        {lists}
                    </div>

                    <div className="col-md-2">&nbsp;</div>
                </div>
            );
        } else {
            return <Loading />;
        }
    }
}
export default Savedfaces2;
