import React, { Component } from 'react';
import { MDBNavbar, MDBNavbarBrand, NavbarNav, MDBNavItem, MDBNavLink, MDBNavbarToggler, MDBCollapse, MDBContainer }
    from 'mdbreact';

class hamburgerMenuPage extends Component {
    state = {
        collapseID: ''
    };

    toggleCollapse = collapseID => () => {
        this.setState(prevState => ({ collapseID: (prevState.collapseID !== collapseID ? collapseID : '') }));
    };

    hamburgerLink(url) {
        console.log(url)
        this.props.history.push(url);
        this.setState(prevState => ({ collapseID: (prevState.collapseID !== 'navbarCollapse1' ? 'navbarCollapse1' : '') }));
    }


    render() {
        return (
            <MDBContainer className="hamburgermenu">
                <MDBNavbar color="light-blue lighten-4" style={{ marginTop: '20px' }} light>
                    <MDBContainer>
                        <MDBNavbarBrand>
                            MENU
                        </MDBNavbarBrand>
                        <MDBNavbarToggler onClick={this.toggleCollapse('navbarCollapse1')} />
                        <MDBCollapse id="navbarCollapse1" isOpen={this.state.collapseID} navbar>
                            <NavbarNav right>
                                <MDBNavItem active>
                                    <MDBNavLink to="/female" onClick={() => {
                                        this.hamburgerLink('/female');}}>FEMALE</MDBNavLink>
                                </MDBNavItem>
                                <MDBNavItem active>
                                    <MDBNavLink to="/male" onClick={() => {
                                        this.hamburgerLink('/male');}}>MALE</MDBNavLink>
                                </MDBNavItem>
                                <MDBNavItem active>
                                    <MDBNavLink to="/society" onClick={() => {
                                        this.hamburgerLink("/society");}}>ICON</MDBNavLink>
                                </MDBNavItem>
                                <MDBNavItem active>
                                    <MDBNavLink to="/agency" onClick={() => {
                                        this.hamburgerLink("/agency");}}>AGENCY</MDBNavLink>
                                </MDBNavItem>
                                <MDBNavItem active>
                                    <MDBNavLink to="/become" onClick={() => {
                                        this.hamburgerLink("/become");}}>BECOME</MDBNavLink>
                                </MDBNavItem>
                                <MDBNavItem active>
                                    <MDBNavLink to="/search" onClick={() => {
                                        this.hamburgerLink("/search");}}>SEARCH</MDBNavLink>
                                </MDBNavItem>
                                <MDBNavItem active>
                                    <MDBNavLink to="/selection" onClick={() => {
                                        this.hamburgerLink("/selection");}}>SELECTIONS</MDBNavLink>
                                </MDBNavItem>

                            </NavbarNav>
                        </MDBCollapse>
                    </MDBContainer>
                </MDBNavbar>
            </MDBContainer>
        );
    }
}

export default hamburgerMenuPage;
